.checkout-methods-items {
  @apply list-none p-0 m-0;
}

#hyva-checkout-container {
  .address-form {
    & > div {
      @apply gap-y-2;
    }
    .label {
      span {
        @apply flex items-center gap-x-1 mb-1;
        &::after {
          @apply relative left-0 top-0 right-auto;
        }
      }
    }
  }

  #payment {
    @apply mb-4;
  }

  .btn-place-order {
    @apply justify-center;
  }
}

.cart {
  &.delivery-info {
    @apply clear-left;

    .delivery-time {
      @apply text-green-500;
    }
  }

  &.trust-logos {
    @apply mb-4 mt-4 pb-4 pt-4 border-t border-b border-solid border-gray-300 clear-left;

    ul {
      @apply m-0 p-0 grid grid-flow-col gap-3 auto-cols-[3rem] list-none;
    }
  }
}

.cart-form {
  h2 {
    @apply mt-0;
  }
}
