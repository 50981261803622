.priceBox {
  @apply text-base lg:text-lg font-serif grid gap-2 grid-areas-price-box grid-cols-price-box;

  .price-details & {
    @apply place-items-center;
  }

  & .special-price, .final-price {
    @apply grid-in-old-price;
    & .price-label {
      @apply sr-only;
    }
  }

  .old-price + .final-price,
  .old-price + .special-price {
    @apply text-red grid-in-price;
  }

  & .old-price {
    @apply line-through text-primary grid-in-old-price;
    & .price-label {
      @apply sr-only;
    }
  }

  & .price-container {
    & .price-wrapper {
      @apply flex;
    }
  }
}

@layer components {
  .price-box {
    @apply priceBox;
  }

  .price-box.price-configured_price {
    @apply flex items-center justify-center;
    & .price-as-configured {
      @apply priceBox self-center;

      .special-price, .final-price {
        @apply font-normal text-red grid-in-price;
      }
    }
  }
}
