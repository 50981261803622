.badges {
  @apply
  absolute
  start-4
  end-4
  -top-0.5
  font-bold
  text-2xl
  flex
  gap-2;
  & .badge {
    @apply
    relative
    h-13
    w-10
    bg-secondary
    flex
    justify-center
    items-center
    text-white
    z-10;
    &:after {
      @apply
      content-['']
      w-full
      h-0
      absolute
      top-full
      left-0
      border-l-[20px]
      border-r-[20px]
      border-t-[40px]
      border-x-transparent
      border-t-secondary;
    }
  }
}
