.form-input, .form-email, .form-select, .form-multiselect, .form-textarea, .input {
    @apply border border-primary rounded-none focus:ring-2 focus:ring-gray-400
}

[type='checkbox'] {
  @apply rounded-none text-primary focus:ring-2 focus:ring-gray-400
}

[type='radio'] {
  @apply text-primary focus:ring-2 focus:ring-gray-400
}

form {
  & .fieldset {
    & .field {
      @apply mt-1;
      &:not(.choice) {
        & .label {
          @apply w-full mb-2 block;
          &.sr-only {
            @apply m-px w-px ;
          }
        }
      }
      &.field-reserved, &.date {
        @apply mb-4;
      }

      & .control {
        input:not(#dob) {
          @apply w-full;
        }
      }
    }
  }
}

form,
fieldset {
  .field.field-error .messages {
    @apply text-red-500 list-none p-0 m-0 w-full;
    max-width: fit-content;
  }
}
