@font-face {
  font-family: "PaymentIcons";
  src: url("../fonts/payment.eot?hnv7hq");
  src: url("../fonts/payment.eot?hnv7hq#iefix") format("embedded-opentype"), url("../fonts/payment.ttf?hnv7hq") format("truetype"), url("../fonts/payment.woff?hnv7hq") format("woff"), url("../fonts/payment.svg?hnv7hq#payment") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa {
  &[class^=icon-], &[class*=" icon-"] {
    font-family: "PaymentIcons", sans-serif !important;
    speak: none;
    top: -1px;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &.icon-rechnung:before {
    content: "\e900";
  }

  &.icon-vorauskasse:before {
    content: "\e902";
  }

  &.icon-sepa:before {
    content: "\e901";
  }

  &.icon-sofort:before {
    content: "\e903";
  }

  &.icon-paydirekt:before {
    content: "\e904";
  }

  &.icon-giropay:before {
    content: "\e905";
  }
}
