@layer components {
  .btn {
    @apply px-4 text-base rounded-none uppercase no-underline;
  }

  .btn-primary {
    @apply border-primary;
    &:hover {
      @apply bg-primary-lighter border-primary-lighter shadow-lg;
    }
  }

  .btn-secondary {
    @apply bg-secondary border-2 border-secondary text-white;

    &:hover {
      @apply bg-secondary-darker border-secondary-darker text-white shadow-lg;
    }

    &:focus {
      @apply border-2 border-transparent;
    }
  }

  .btn-light {
    @apply bg-white border-2 border-primary text-primary;

    &:hover {
      @apply bg-white border-primary-lighter text-primary shadow-lg;
    }

    &:focus {
      @apply border-2 border-transparent;
    }
  }

  .btn-lg {
    @apply px-10 py-4 text-lg;
  }

  .btn-sm {
    @apply px-2 py-2 text-sm;
  }
}
