.bmx\:list-inline.blogcrumbs {
  @apply pl-0;

  li:after {
    @apply bg-transparent h-auto content-['-'];
  }
}

.bmx\:content {
  & > [data-content-type="row"][data-appearance="contained"] {
    @apply px-0;
  }
  li {
    @apply mb-0;
  }
}
