[data-content-type="products"] {
  .product-slider {
    .js_slides {
      @apply relative flex flex-nowrap w-full overflow-auto snap md:px-1 xl:px-2 @container;

      .js_slide {
        @apply flex shrink-0 w-full mr-1 p-1 @xl:w-1/2 @3xl:w-1/3 @7xl:w-1/4;

        .item {
          .cta {
            .add-to-cart {
              & > button {
                .icon {
                  @apply inline-flex @lg:hidden;

                  &.is-grid {
                    @apply inline-flex @4xl:hidden;
                  }
                }

                .text {
                  @apply @md:hidden @lg:inline;

                  &.is-grid {
                    @apply @md:hidden @lg:ml-0 @4xl:inline;
                  }
                }
              }
            }
          }
        }
      }

      .js_dummy_slide {
        @apply w-full flex shrink-0 py-1 @xl:w-1/2 @3xl:w-1/3 @7xl:w-1/4;
      }
    }
  }
}
