.store-locator-module {
  .store-locator-navigation {
    > input {
      @apply mb-4;
    }
  }
  .store-locator-results {
    .store-locator-result {
      @apply no-underline normal-case text-primary p-4 hover:bg-gray-100;
      .store-locator-result-name {
        @apply font-bold uppercase;
      }

      address {
        @apply pt-2 pb-4;
      }

      .email {
        @apply underline whitespace-nowrap;
      }
    }
  }

  .store-locator-popup {
    .result-name {
      @apply text-primary font-bold uppercase;
    }

    .result-address {
      @apply text-primary pb-4;
    }

    .result-email, .result-website {
      @apply text-primary hover:text-primary-darker underline whitespace-nowrap;
    }

    .result-email {
      @apply normal-case mb-4;
    }
  }
}
