.order-items > div:nth-child(even) {
    @apply bg-container-darker;
}

.order-links {
    @apply block items-center bg-container;
}

.order-links li {
    @apply inline-block py-2 px-4 cursor-pointer whitespace-nowrap;
}

.order-links li.current {
    @apply flex-grow text-primary;
}

.order-links li a {
    @apply underline;
}

.order-date {
    @apply text-sm;
}
