.messages {
  @apply mb-6;
  .message {
    span {
      a {
        @apply text-white hover:text-gray-300;
      }
    }
  }
}
