body {
    @apply bg-container;
}

#cart-drawer {
    @apply text-black;
}

[x-cloak] {
    display: none !important;
}

.input {
    @apply w-auto rounded mr-2 border text-base py-2 px-4;

    @screen md {
        @apply mr-4;
    }

    @screen lg {
        @apply mr-0;
    }

    @screen xl {
        @apply mr-4;
    }

    &-light {
        @apply bg-white border-gray-400;

        &:focus {
            @apply outline-none border-primary;
        }
    }
}

.card {
    @apply p-6 rounded-sm bg-container-lighter shadow-lg;
}

.card-interactive {
    &:hover {
        @apply bg-container;
    }
}
