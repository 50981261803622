[wire\:loading],
[wire\:loading\.delay],
[wire\:loading\.inline-block],
[wire\:loading\.inline],
[wire\:loading\.block],
[wire\:loading\.flex],
[wire\:loading\.table],
[wire\:loading\.grid],
[wire\:loading\.inline-flex] {
    display: none;
}

[wire\:loading\.delay\.shortest],
[wire\:loading\.delay\.shorter],
[wire\:loading\.delay\.short],
[wire\:loading\.delay\.long],
[wire\:loading\.delay\.longer],
[wire\:loading\.delay\.longest] {
    display: none;
}

[wire\:offline] {
    display: none;
}

[wire\:dirty]:not(textarea):not(input):not(select) {
    display: none;
}

input:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill {
    animation-duration: 50000s;
    animation-name: livewireautofill;
}

@keyframes livewireautofill { from {} }

#livewire-error iframe {
    background-color: rgb(255 255 255) !important;
    border-radius: 0.375rem !important;
    padding: 0.75rem 1rem !important;
}