.account-nav ul {
  @apply p-0 m-0 list-none;
  & li {
    & a, & strong {
      @apply flex justify-between text-primary py-1 normal-case no-underline;
    }

    & a {
      @apply hover:text-black;
    }

    & strong {
      @apply underline text-black font-normal;
    }
  }
}

.actions-toolbar {
    @apply mt-6 border-t border-container-darker pt-4 flex justify-between flex-row-reverse items-center
}


@layer components {
    .actions-toolbar .primary button {
        @apply btn btn-primary;
    }
}

.actions-toolbar a.back {
    @apply text-secondary-darker underline
}

.account {
  & .block:not(.block-dashboard-orders):not(.block-addresses-default):not(.block-addresses-list) {

    & .block-title {
      @apply my-6 flex justify-between items-center card;
    }

    & .block-content {
      @apply mb-6;

      & > div {
        @apply grid grid-cols-1 lg:grid-cols-2 justify-between gap-4;

        & > div {
          @apply flex flex-col h-full p-8 border border-gray-200 sm:flex-row card;

          & a {
            @apply
              inline-flex
              items-center
              w-full
              mt-3
              md:text-sm
              text-primary
              hover:text-primary-darker
              no-underline
              normal-case;
          }

          & address {
            & a {
              @apply inline;
            }
          }
        }
      }
    }
  }
}
