.eventcalendar-event-eventlist {
  .eventlist-header {
    @apply grid grid-flow-col grid-cols-[1fr_auto_1fr] items-center;
  }

  .columns .widget.widget_upcoming {
    ul {
      @apply items-start p-0;

      li {
        @apply grid grid-cols-[30%_1fr] gap-2 w-full;

        .event-image {
          @apply w-full;
        }

        .event-info {
          a {
            @apply text-primary;
          }
        }
      }
    }
  }
}

.event-page-description {
  h1 [data-ui-id="page-title-wrapper"] {
    @apply text-2xl sm:text-3xl md:text-4xl;
  }
}
