#html-body :is(.image-text, .image-row) {

  .pagebuilder-column-group,
  .pagebuilder-column-line {
    @apply gap-4;

    figure, figure img {
      @apply w-full h-full;

      &:is(img) {
        @apply object-cover;
      }
    }
  }

  &:is(.image-row) {
    .pagebuilder-column {
      @apply aspect-[3/2];
    }
  }

  &:is(.image-text) {

    .pagebuilder-column-group,
    .pagebuilder-column-line {
      @apply flex-wrap lg:flex-nowrap;

      .pagebuilder-column {
        @apply max-lg:w-auto;

        &:has([data-content-type="image"]) {
          @apply aspect-[16/9];
        }

        &:is(:first-child:nth-last-child(3)) {
          & ~ .pagebuilder-column:has([data-content-type="image"]) {
            @apply aspect-[3/2];
          }
        }
      }
    }
  }
}
