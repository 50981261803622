/* styles for paypal cards */
@layer components {
    .pp-card-cvv {
        input {
            @apply w-[10ch] box-content;
        }
    }

    .pp-card-number {
        input {
            @apply sm:w-[16ch] w-full box-content;
        }
    }

    .pp-number-input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.pp-stored-cards {
    .pp-card-view {
        @apply cursor-pointer transition hover:shadow-lg active:opacity-70;
    }
    input[type='radio'] {
        @apply hidden cursor-pointer;
        &:checked {
            & + .pp-card-view {
                @apply shadow-pp_card_shadow cursor-default;
            }
        }
    }
}


