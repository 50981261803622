.page.messages {
    @apply sticky z-20;
    top: 0px;

    .messages
    section#messages {
        @apply container mx-auto py-3;
    }
}

.message {
    @apply flex items-center justify-between w-full p-2 bg-gray-600 shadow rounded text-white mb-2;
}

.message {
    &.error {
        @apply bg-red-500;
    }

    &.success {
        @apply bg-green-500;
    }

    &.info,
    &.warning,
    &.notice {
        @apply bg-yellow-500;
    }

    a {
        @apply underline
    }
}
