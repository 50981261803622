.price-details {
  @apply text-sm lg:text-base;
  & > ul {
    @apply list-none p-0 m-0 flex flex-wrap gap-1;
    & li {
      &:not(:last-child) {
        &:after {
          @apply p-1;
          content: '|';
        }
      }
    }
  }
}

#product-details {
  h1, h2, h3, h4, h5, h6 {
    @apply mb-0;
  }
}
