.events-calendar-details .events-content {
  @apply grid lg:grid-cols-[40%_60%] gap-8;

  & > [class*="events-single"] {
    @apply float-none w-full;

    &[class*="right"] {
      @apply lg:order-1 pl-0;

      .back_event {
        @apply my-0 float-none;
      }
    }

    &[class*="left"] {
      @apply border-x-0 lg:border-r;
    }
  }

  .event-image {
    @apply aspect-auto;
  }

  .events-abbr {
    @apply font-bold no-underline;
  }

  a {
    @apply text-primary;
  }
}

.eventcalendar-event-eventview {
  h1 {
    span[data-ui-id="page-title-wrapper"] {
      @apply text-2xl sm:text-3xl md:text-4xl;
    }
  }
}
