.mst-search__result-tabs {
  @apply list-none flex flex-row flex-wrap border-b border-gray-300 px-2;

  & li {
    @apply mr-1 py-2 border border-b-0;

    & a {
      @apply px-4 py-2 no-underline text-primary bg-zinc-200;

      &.active {
        @apply bg-transparent;
      }

      &:hover {
        @apply bg-gray-100;
      }
    }
  }
}

.mst-search__list {
  & a {
    @apply text-primary hover:text-primary-lighter no-underline;
  }
}
