.page-header {

  & #header > .container {
    @apply gap-x-0 lg:gap-x-4 xl:gap-x-16;

    & .header-icons {
      a {
        @apply text-primary hover:text-black;
      }

      & svg {
        @apply w-6 h-6 md:w-8 md:h-8;
      }
    }

    & .logo {
      @apply sm:min-h-12 md:min-h-16;
    }

    & nav .nav-item {

      a {
        @apply no-underline text-primary;
      }

      & > span a {
        @apply lg:py-3 lg:text-base xl:text-lg;
        &.active {
          @apply text-black;
        }
      }

      & .submenu {
        @apply p-0;
        display: grid;
        grid-template-rows: 0fr;
        grid-template-columns: max-content;
        transition: grid-template-rows 250ms ease-in-out;

        & > div {
          overflow-y: hidden;
          & a {
            @apply px-9;
            &:first-child {
              @apply mt-6;
            }
            &:last-child {
              @apply mb-6;
            }
            &:hover {
              @apply bg-gray-100;
            }
            &.active {
              @apply bg-gray-200;
            }
          }
        }
      }

      &:hover .submenu {
        grid-template-rows: 1fr;
      }
    }

    & .navigation {

      &.mobile {

        & .drawer {
          @apply fixed h-screen overflow-x-hidden overflow-y-auto fixed top-0 w-full;
          left: -100%;
          transition: left 300ms ease-in-out;
        }

        &.open .drawer {
          @apply left-0;
        }
      }

      &.desktop {

        &[data-area="left"] {
          @apply lg:grid-in-nav-left justify-self-end;
        }

        &[data-area="icons"] {
          @apply lg:grid-in-nav-icons justify-self-start;
        }

        & nav {
          @apply items-center lg:gap-x-4 xl:gap-x-7;
        }
      }
    }
  }

  /* Checkout header */
  & > div.header {
    @apply grid grid-cols-header-container grid-areas-header-container py-3;
  }
}

nav[aria-labelledby="customer-menu"] {
    a {
      @apply no-underline;
    }
}
