#mirasvitSearchResults {
  --max-height: min-content;

  @apply
    border-solid
    border
    w-full
    p-2
    mt-1
    text-lg
    leading-normal
    transition
    appearance-none
    lg:text-xl
    relative
    overflow-y-auto;

  max-height: var(--max-height);

  & .products {
    @apply
      container
      mx-auto
      grid
      grid-cols-1
      sm:grid-cols-2
      lg:grid-cols-3
      xl:grid-cols-5
      mb-4
      p-2
      md:pt-6
      gap-4;

    & .product {
      @apply
        md:max-w-md
        bg-white
        border
        overflow-hidden;

      & > a {
        @apply
          w-full
          h-full
          grid
          gap-2
          grid-areas-product-grid-tile
          grid-rows-product-grid-tile
          place-items-center
          no-underline;

        & .image {
          @apply grid-in-image grid p-2 aspect-square w-full place-content-center;

          & > img {
            @apply w-full h-full object-contain;
          }
        }

        & .info {
          @apply grid-in-info p-2;

          & .title {
            @apply text-gray-900 text-sm normal-case text-center;
          }
        }
      }
    }
  }

  & .section {
    & a {
      @apply
        normal-case
        no-underline;
    }
    & .section-title {
      @apply text-lg leading-normal transition appearance-none lg:text-xl border-b border-gray-200 p-2 m-0 flex;
    }

    & .item {
      @apply my-2 flex flex-wrap;

      & .item-name {
        @apply m-1 font-bold text-sm leading-loose cursor-pointer text-primary hover:text-primary-lighter;
      }
    }

    & .item-number {
      @apply text-gray-500 ml-1;
    }
  }
}

.view-all {
  @apply w-full order-2 col-span-12;
}

.no-results {
  @apply bg-gray-100 p-2;

  & > p {
    @apply text-lg leading-normal transition appearance-none text-gray-800 lg:text-xl;
  }
}
