.page-main {

}

.table-row-items > div.table-row-item {

    @apply bg-container-darker;

    &:nth-child(2n + 1) {
        @apply bg-container-lighter;
    }
}
