.blog__posts__type-10__title.bmx\:card-title {

  @apply text-primary font-normal;

  &:after {
    @apply content-none;
  }

}
.blog__posts__type-10.bmx\:card {
  @apply grid grid-cols-1 md:grid-cols-2 gap-4 bg-transparent border-0 md:w-4/5 md:mx-auto p-0;

  .post {
    @apply grid grid-cols-[33%_auto] gap-2;

    .image {
      @apply aspect-[16/9] w-full;

      img {
        @apply object-cover w-full h-full;
      }
    }

    .text {

      @apply text-primary text-sm;

      .category, .date {
        @apply text-inherit;
      }

      .title {
        @apply lg:text-xl md:text-sm text-lg block;
        a {
          @apply no-underline normal-case font-serif text-inherit;
        }
      }
    }
  }
}
