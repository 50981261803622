#category-view-container {
  @apply mx-auto flex px-0 py-0 flex-col items-center;

  .category-cms [data-content-type="row"]:is([data-appearance="full-width"], [data-appearance="full-bleed"]) {
    margin-inline: calc(-1 * (50vw - 50%) + var(--scrollbar-width) / 2);
  }
}

#footer-description {
  @apply mx-auto flex px-0 py-4 flex-col items-center;
}
