:root {
  --bmx-primary: #fb3003;
  --bmx-border: #ebebeb;
  --bmx-link: #203656;
  --bmx-link-hover: #4e678a;
  --bmx-subdued: #8f9bad;
  --bmx-gradient: linear-gradient(to right, #fb3003 0%, #ffa387 100%);
}

.bmx\:card {
  @apply rounded p-6;
  border: 1px solid var(--bmx-border);
  background: #ffffff;
}

.bmx\:card-title {
  @apply text-xl font-bold text-gray-700 flex flex-col mb-6;
}

.bmx\:card-title::after {
  @apply mt-2;
  content: '';
  display: block;
  width: 26px;
  height: 6px;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='6' viewBox='0 0 26 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3_15)'%3E%3Crect width='26' height='6' fill='white'/%3E%3Ccircle cx='3' cy='3' r='3' fill='%23FB3003'/%3E%3Ccircle cx='13' cy='3' r='3' fill='%23FD876D'/%3E%3Ccircle cx='23' cy='3' r='3' fill='%23FED4CA'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3_15'%3E%3Crect width='26' height='6' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.bmx\:link {
  color: var(--bmx-link);
  text-decoration: none;
}

.bmx\:link:hover {
  color: var(--bmx-link-hover);
  text-decoration: none;
}

.bmx\:text-subdued {
  color: var(--bmx-subdued);
}

.bmx\:list-inline {
  @apply flex gap-3 items-center flex-wrap;
}

.bmx\:list-inline li {
  @apply flex items-center;
}

.bmx\:list-inline li::after {
  content: "";
  display: inline-block;
  background-color: var(--bmx-primary);
  border-radius: 50%;
  margin-left: .75rem;
  height: 3px;
  vertical-align: middle;
  position: relative;
  width: 3px;
}

.bmx\:list-inline li:last-child::after {
  display: none;
}

.bmx\:thumb::after {
  content: "";
  background: var(--bmx-link);
  display: block;
  height: 100%;
  left: 0;
  opacity: .6;
  top: 0;
  position: absolute;
  width: 100%;
}

.bmx\:category-badge {
  background: var(--bmx-gradient);
  color: #ffffff;
  font-size: 13px;
  line-height: 1;
  border-radius: 25px;
  display: inline-block;
  padding: 6px 11px;
  background-size: 200% auto;
}

.bmx\:number {
  @apply shadow;
  content: '';
  color: #fff;
  display: block;
  border: solid 2px #fff;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  line-height: 20px;
  left: -12px;
  text-align: center;
  top: -12px;
  position: absolute;
  width: 24px;
  background: var(--bmx-gradient);
  z-index: 1;
}

.bmx\:hr {
  display: block;
  height: 1px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  background: var(--bmx-border);
  background: linear-gradient(to left, var(--bmx-border) 0%, transparent 100%)
}

.bmx\:content h2 {
  @apply text-xl mb-4 mt-8;
}

.bmx\:content h3 {
  @apply text-lg mb-4 mt-8;
}

.bmx\:content p, .bmx\:content li, .bmx\:content img {
  @apply mb-4;
}

.bmx\:content ul {
  @apply pl-8;
  list-style: disc;
}

.bmx\:min-h-250 {
  min-height: 250px;
}

.bmx\:mask {
  background-color: var(--bmx-subdued);
}
