.products.wishlist {
  & .item.product {
    & .product-item-info {
      & .product-item-name {
        & .product-item-link {
          @apply
            mt-2
            items-center
            justify-center
            text-primary
            text-lg
            text-center
            font-serif
            font-normal
            normal-case
            no-underline;
        }
      }

      & .product-item-inner {
        .box-tocart {
          .fieldset {
            @apply mt-auto pt-3 grid grid-cols-[1fr_3fr] justify-items-start;

            & .field {
              @apply m-0;
            }
          }
        }
      }
    }
  }
}

