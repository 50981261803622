.blog__featured-image__default {
  @apply relative mb-8;

  & > .title {
    @apply absolute w-full h-full grid place-items-center text-white before:content-[''] before:inset-0 before:bg-black/[.4] before:absolute;

    h1 {
      @apply text-4xl lg:text-6xl drop-shadow-lg;
    }
  }

  .image {
    @apply w-full aspect-square md:aspect-[21/9] overflow-hidden;

    & > img {
      @apply w-full h-full object-cover;
    }
  }
}
