.item {
  &.product.product-item {
    @apply card card-interactive grid w-full;

    & .product-item-info {
      & .title {
        @apply
        mt-2
        items-center
        justify-center
        text-primary
        text-lg
        text-center;

        & small {
          @apply block text-xs;
        }

        & > a {
          @apply
          font-serif
          text-inherit
          text-lg
          no-underline
          normal-case;

          &:hover {
            @apply underline;
          }
        }
      }

      & .price-details {
        @apply
        pt-1
        text-center
        flex
        flex-col
        items-center
        justify-center;

        & .price-box {
          & .price-container {
            @apply flex flex-col items-center justify-center;

            & .price {
              @apply text-lg font-normal p-0 leading-none;
            }
          }
        }

        .price-meta {
          @apply text-xs text-gray-400 uppercase;
        }
      }

      & .cta {
        @apply mt-auto pt-3 flex flex-wrap items-center gap-2;

        .add-to-cart {
          & > button {
            .icon {
              @apply inline-flex md:hidden;

              &.is-grid {
                @apply inline-flex 2xl:hidden;
              }
            }

            .text {
              @apply hidden md:inline;

              &.is-grid {
                @apply hidden md:ml-0 2xl:inline;
              }
            }
          }
        }
      }
    }
  }
}
