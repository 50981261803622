.page-footer {
  @apply
  pt-4
  bg-neutral-100
  border
  border-t-primary-lighter
  pb-8;

  & .footer.content {
    @apply
    container
    py-10
    mx-auto
    grid
    gap-8
    grid-areas-[links,newsletter,payment,copyright]
    lg:grid-areas-[links_newsletter,links_payment,copyright_copyright]
    lg:grid-cols-[2fr_1fr];
  }

  & ul {
    @apply
    list-none
    mt-1
    mb-0
    p-0;

    & li {
      & a {
        @apply no-underline text-md;
      }
    }
  }
}
