#html-body .pb-icon-text-list {
  & .pagebuilder-column-group {
    @apply tracking-widest uppercase !grid grid-cols-icon-text-small lg:grid-cols-icon-text-large;

    & .pagebuilder-column {

      @apply grid w-full grid-rows-icon-text-column pl-2 pr-2;

      & i.fa {
        @apply inline-grid place-content-center bg-primary border-4 border-white rounded-full w-[52px] text-[22px] aspect-square text-white;
      }
    }
  }
}
