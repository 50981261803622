.eventcalendar-event-eventlist, .eventcalendar-event-eventview {

  .card-interactive {
    @apply p-0 grid;
  }

  .event_loop {
    .item {

      @apply grid grid-rows-[min-content_auto] h-full;

      .event-text {
        @apply relative grid grid-rows-[auto_min-content] p-8 pt-4;

        a {
          @apply absolute inset-0;
        }
      }
    }
  }

  .event-image {
    @apply aspect-[3/2];

    a {
      @apply block w-full h-full;

      img {
        @apply block w-full h-full object-cover;
      }
    }
  }
}
