.form-edit-account {
  & .fieldset {
    & .field {
      & .control {
        input:not(#dob) {
          @apply w-1/2;
        }
      }
    }
  }
}
