.duration-200 {
    transition-duration: 200ms;
}
.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.transition {
    transition: transform 250ms ease, color 250ms ease;
}
.transform-180 {
    transform: rotate(-180deg);
}
