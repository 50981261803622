.blog__category {
  &__default, &__subcategories {
    h3 {
      @apply mt-0;
    }

    .categories:has(.is-anchor) {
      a:not(.is-anchor) {
        @apply pl-4;
      }
    }
  }

  &__image-list {
    .category {
      & > a {
        @apply relative block;

        .image {
          @apply w-full h-full aspect-square md:aspect-[3/2] overflow-hidden relative;

          & > img {
            @apply w-full h-full object-cover;
          }
        }

        .text {
          @apply absolute inset-0 grid content-end text-white z-10 p-6 transition-all ease-in-out duration-200;


          .name {
            @apply font-serif text-2xl md:text-base xl:text-xl lg:text-lg transition-all ease-in-out duration-300 origin-left;
          }

          .description {
            @apply text-sm font-normal transition-all ease-in-out duration-300 origin-left;
          }

          &:after {
            @apply absolute inset-0 content-[''] bg-gradient-to-t from-black to-transparent to-50% transition-all ease-in-out duration-300 z-[-1] opacity-60;
          }

          &:hover {
            @apply pb-8;

            &:after {
              @apply opacity-80;
            }

            .name {
              @apply scale-105;
            }
          }

        }
      }
    }
  }
}
