body {
    @apply text-primary leading-normal text-base tracking-normal;
}

@layer base {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    @apply font-serif font-normal;
  }

  h1, .h1 {
    @apply text-2xl lg:text-4xl my-2.5em;
  }

  h2, .h2 {
    @apply text-xl lg:text-2xl my-3.5em;
  }

  h3, .h3 {
    @apply text-lg lg:text-xl my-4em;
  }

  a {
    @apply text-primary-lighter underline uppercase;

    &:hover {
      @apply text-primary;
    }
  }

  p {
    @apply my-4;
  }

  ul {
    @apply list-disc pl-10 my-4;
  }
}


